
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

.navbar {
  min-height: 57px;
}

.navbar-light .social{
  color: rgba(255, 255, 255, .65);
}

.navbar-stick-dark.stick .social{
  color: rgba(117, 117, 117, .8);
}

.navbar-light .nav-navbar>.nav-link.elsoft {
  color: #87cefa;
}

.navbar-light .nav-navbar>.nav-link.elsoft:hover {
  color: #00ffff;
  
}

.navbar-stick-dark.stick .nav-navbar>.nav-link.elsoft {
  color: #3c96ff;
}

.navbar-stick-dark.stick .nav-navbar>.nav-link.elsoft:hover {
  color: #007bff;
  
}

.text-help {
  color: #007bff; /* Bootstrap primary blue for example */
}

.media-body {
  cursor: pointer;
}

.media:hover{  
  h5{
    color: blue;
  }
  
}

.media:hover::before i{
  transform: scale(1.1);
  //box-shadow: 0 0 15px blue;
}

.media:hover i{
  //color: blue;
  //box-shadow: 0 0 5px blue;
  text-shadow: 0 0 3px #00aafb;
}

.media:hover .fa-circle-question {
  color: blue;
  text-shadow: none;
}

.text-sand {
  color: #da9900;
}

.mr-2-0 {
  margin-right: 2rem;
}

.mr-2-3 {
  margin-right: 2.3rem;
}

.mr-2-2 {
  margin-right: 2.2rem;
}

.mr-1-7 {
  margin-right: 1.7rem;
}

.mr-1-5 {
  margin-right: 1.5rem;
}

.mr-1-4 {
  margin-right: 1.4rem;
}
.mr-1-3 {
  margin-right: 1.3rem;
}

.text-red {
  color: red;
}

.text-litebrown {
  color: #d2b48c;
}

.text-brown {
  color: brown;
}

.text-blue {
  color: blue;
}

.text-orange{
  color: orange;  
}

.text-darkgray{
  color: #333;
}

.mx-auto{
  max-width: 508px;
}

.no-events {
  pointer-events: none;
}

.no-events a, .no-events img {
  pointer-events: auto;
}

.section-header {
  margin-bottom: 0;
}

.block-number {
  text-align: center;
  font-weight: bold;
  font-size: 0.8125em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #191919;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out; }
  .block-number:hover {
    opacity: 1; }
  .block-number::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6px;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #191919; }
  .block-number a:hover,
  .block-number a:focus {
    color: #191919; }

.demo-section .main-content .section,
.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar,
.demo-header .main-content .header,
.demo-footer .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.05); }

.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar {
  position: static;
  margin: 0; }

.sample-blocks img {
  border-radius: 0.25rem; }
  .sample-blocks img.aos-animate {
    opacity: 0.5 !important; }
  .sample-blocks img:hover {
    opacity: 1 !important;
    -webkit-transition: 0.7s !important;
    transition: 0.7s !important; }

.sample-blocks a {
  position: absolute; 
}

.sample-blocks a:nth-child(1) {
  right: 15px;
  bottom: 0px; }

.sample-blocks a:nth-child(2) {
  right: 320px;
  bottom: 30px; }

.sample-blocks a:nth-child(3) {
  right: 0px;
  bottom: 230px; }

.sample-blocks a:nth-child(4) {
  right: 280px;
  bottom: 220px; }

.sample-blocks a:nth-child(5) {
  right: 270px;
  bottom: 430px; }

.sample-blocks a:nth-child(6) {
  right: 20px;
  bottom: 435px; }

.sample-blocks a:nth-child(7) {
  right: 200px;
  bottom: 610px; }

.sample-blocks a:nth-child(8) {
  right: 10px;
  bottom: 580px; }

.sample-blocks a:nth-child(9) {
  right: 0px;
  bottom: 660px; }


  .sample-blocks a:nth-child(10) {
    left: 15px;
    bottom: -20px; }
  
  .sample-blocks a:nth-child(11) {
            left: 320px;
    bottom: 30px; }
  
  .sample-blocks a:nth-child(12) {
            left: 0px;
    bottom: 210px; }
  
  .sample-blocks a:nth-child(13) {
            left: 280px;
    bottom: 220px; }
  
  .sample-blocks a:nth-child(14) {
            left: 170px;
    bottom: 420px; }
  
  .sample-blocks a:nth-child(15) {
            left: 20px;
    bottom: 415px; }
  
  .sample-blocks a:nth-child(16) {
            left: 200px;
    bottom: 590px; }
  
  .sample-blocks a:nth-child(17) {
            left: 10px;
    bottom: 560px; }
  
  .sample-blocks a:nth-child(18) {
            left: 0px;
    bottom: 640px; }
  
// @-webkit-keyframes wobble {
//   0% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); }
//   50% {
//     -webkit-transform: rotate(1deg) translate(5px, 5px);
//             transform: rotate(1deg) translate(5px, 5px); }
//   100% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); } }

// @keyframes wobble {
//   0% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); }
//   50% {
//     -webkit-transform: rotate(1deg) translate(5px, 5px);
//             transform: rotate(1deg) translate(5px, 5px); }
//   100% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); } }

@keyframes wobble {
  0%, 100% {
      transform: translate(-2px, -2px);
  }
  50% {
      transform: translate(2px, 2px);
  }
}

@keyframes wobble {
  0%, 100% {
      transform: translate3d(-2px, -2px, 0);
  }
  50% {
      transform: translate3d(2px, 2px, 0);
  }
}

// .sample-blocks a:nth-child(odd) {
//   // -webkit-animation: wobble 4s infinite;
//   // animation: wobble 4s infinite;
//   will-change: transform;
//   /* or, if `will-change` causes too many resources to be used */
//   //transform: translateZ(0); /* or `translate3d(0, 0, 0)` to force hardware acceleration */

//   animation: wobble 5s infinite linear;
//   //position: relative; /* Ensure transform properties work as expected */
// }

// .sample-blocks a:nth-child(even) {
//   -webkit-animation: wobble 7s infinite;
//   animation: wobble 7s infinite;
// }


/* Example of a minimal impact version using translate3d to always engage GPU */
// .sample-blocks a:nth-child(odd) {
//   /* Using translate3d to ensure GPU acceleration */
//   transform: translate3d(0, 0, 0);
//   animation: wobble 5s infinite linear;
// }

@media (max-width: 400px) {
  body > .footer .social a:last-child,
  body > .footer .nav a:last-child {
    display: none; } }

/*# sourceMappingURL=style.css.map */
